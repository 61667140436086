import { useMemo, useRef, useState } from 'react';
import './App.css';
import { db, auth } from './firebase';
import { onValue, ref, remove, child, push, set } from 'firebase/database';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { addMonthName } from './utils';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut , getAuth } from '@firebase/auth';
import MainDashboard from './MainDashboard';
const expensesRef = ref(db, "expenses")


export var userId = localStorage.getItem("userId");


function App() {

	const emailTbRef = useRef();
	const passwordTbRef = useRef();


	useEffect(() => {
		window.userId = localStorage.getItem("userId");
		console.log(getAuth().currentUser)
	}, [])


	function signIn() {
		signInWithEmailAndPassword(auth, emailTbRef.current.value, passwordTbRef.current.value).then(cred => {
			window.user = cred.user
			userId = cred.user.uid
			localStorage.setItem("userId", cred.user.uid)
			window.location.reload()
		}).catch(error => {
			console.log(error)
		});
	}

	function signUp() {
		createUserWithEmailAndPassword(auth, emailTbRef.current.value, passwordTbRef.current.value).then(cred => {
			window.user = cred.user
			userId = cred.user.uid
			localStorage.setItem("userId", cred.user.uid)
			window.location.reload()
		}).catch(error => {
				console.log(error)
		});
	}

	return(
		<div>
		{localStorage.getItem("userId") !=  null ? <MainDashboard/> : 
	<div id='signIn'>
		<div id='signInCard'>
			<h2>Einloggen:</h2>
			<input type="tel" placeholder='Email-Adresse' id='telNrTb' ref={emailTbRef} />
			<input type="password" id='otpTb' placeholder='Passwort' ref={passwordTbRef} />
			<input type="button" className="sign-in-button" value="Anmelden" onClick={() => signIn()} />
			<input type="button" className="sign-in-button" value="Registrieren" onClick={() => signUp()} />
		</div>
	</div>}
	</div>
	)
}

export default App;
