import { useMemo, useRef, useState } from 'react';
import './App.css';
import { db } from './firebase';
import { onValue, ref, remove, child, push, set } from 'firebase/database';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { addMonthName } from './utils';
import { userId } from './App';

const expensesRef = ref(db, "expenses")

export function MonthExpenses() {
	var {month} = useParams()

	const currentMonthRef = useMemo(() => ref(db, "expenses/" + userId + "/" + month), [month])

	const [data, setData] = useState([])
	
 
	var monthName = addMonthName(month);

	const titleTbRef = useRef()
	const costsTbRef = useRef()
	const isPositiveRef = useRef()

	useEffect(() => {
		onValue(currentMonthRef, snap => {
			var MonthsTemp = []
			for(var month in snap.val()){
				  MonthsTemp[MonthsTemp.length] = {...snap.val()[month], id: month, monthName: monthName}
			}
			setData(MonthsTemp);
		  })
		  // eslint-disable-next-line
	}, [])


	function removeExpense(id) {
		var el = child(currentMonthRef, id)
		remove(el)
	}

	function addExpense() {
		var costs = isPositiveRef.current.checked === false ? costsTbRef.current.value * -1 : costsTbRef.current.value;
		var d = new  Date()
		var dateString = ('0' + d.getDate()).slice(-2) + '.'
             + ('0' + (d.getMonth()+1)).slice(-2) + '.'
             + d.getFullYear();

		var data = {
			title: titleTbRef.current.value,
			costs: costs,
			date: dateString
		}


	push(currentMonthRef, data).then(e => {
		titleTbRef.current.value = ""
		costsTbRef.current.value = ""
	})
	}

	function deleteMonth() {
		remove(child(expensesRef, month)).then(e => {
		window.location.hash = "#"})

		//console.log(child(expensesRef, month))
	}

	 window.onkeydown = e => {
	 	if(e.key === "Enter" && titleTbRef.current.value && costsTbRef.current.value){		
			addExpense()
	 	}
	 }

	 var balance = 0;

	return(
		<div id='ausgaben'>
			<h1>Ausgaben - Gehalt für {monthName}</h1>
			<div id='monthExpenses'>
				{	
					data.map(expense => {
						if(expense.costs !== 0) {
							balance += parseInt(expense.costs)
						return (
						<div><div className="expense" key={expense.id}>
							<p className="expenseDate">{expense.date.replace("2023", "")}</p>
							<h2 className="expenseTitle"><bdi>{expense.title}</bdi></h2>
							<h2 className={(expense.costs > 0) ? "expenseCost positive" : "expenseCost"}>{expense.costs}€</h2>
							</div>
							<button className='deleteBtn' onClick={() => removeExpense(expense.id)}><i className="fa-solid fa-trash"></i></button>
						</div>)
					}
					else {
						return null;
					}
				})
				}
			</div>
			<div className="expense" id='balance' >
						<h2 className="expenseTitle">jetziger Stand:</h2>
						<h2 className={(balance > 0) ? "expenseCost positive" : "expenseCost"}>{balance}€</h2>
			</div>

			<div id="addExp">
				<h1 className="addTitle">Ausgabe hinzufügen:</h1>
				<div>
					<input type="text" ref={titleTbRef} id="addTitleTb" placeholder='Titel' />
					<input type="text" ref={costsTbRef} placeholder='€' id="addCostsTb" /> <br/>
					<input type='checkbox' id='chk' ref={isPositiveRef}/> <label htmlFor="chk">Betrag bekommen? </label> <br/>
					<button id='addBtn' onClick={() => addExpense()}>Hinzufügen</button>
				</div>
			</div>

			<div id='footer'>
				<p>Hier kannst du den ganzen Monat löschen:</p>
				<button id='deleteMonthBtn' onClick={() => deleteMonth()}>Monat löschen</button>

			</div>
		</div>
	)
}
