export function addMonthName(num) {
	var monthName
	switch(num.toString()) {
		case "1":
			monthName = "Januar"
			break;
		case "2":	
		monthName = "Februar"			
			break;
		case "3":
			monthName = "März"
			break;
		case "4":
			monthName = "April"
			break;
		case "5":
			monthName = "Mai"
			break;
		case "6":
			monthName = "Juni"
			break;
		case "7":
			monthName = "Juli"
			break;
		case "8":
			monthName = "August"
			break;
		case "9":
			monthName = "September"
			break;
		case "10":
			monthName = "Oktober"
			break;
		case "11":
			monthName = "November"
			break;
		case "12":
			monthName = "Dezember"
			break;
		default: 
			monthName = num.toString();
		break;
	}
	return monthName
  }
