import { useMemo, useRef, useState } from 'react';
import './App.css';
import { auth, db } from './firebase';
import { onValue, ref, remove, child, push, set } from 'firebase/database';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { addMonthName } from './utils';
import { signOut } from '@firebase/auth';
import { userId } from './App';

const expensesRef = ref(db, "expenses/" + localStorage.getItem("userId"))




function MainDashboard() {
    const [Months, setMonths] = useState([])
    const [rawData, setRawData] = useState({})
  
    const monthTbRef = useRef()
    
    useEffect(() => {
        onValue(expensesRef, snap => {
  
          var MonthsTemp = []
          var monthName = ""          
  
          for(var month in snap.val()){
              monthName = addMonthName(month)
                MonthsTemp[MonthsTemp.length] = {...snap.val()[month], id: month, monthName: monthName}
          }
          setMonths(MonthsTemp);
          setRawData(snap.val())
        })
    }, [])

    
    
  
    function addMonth() {
      var data = {...rawData};
      data[monthTbRef.current.value] = {
          init: {
              title: "init",
              costs: 0,
              date: ""
          }
      }
  
      set(expensesRef, data).then(e => {
          monthTbRef.current.value = ""
      })
  
    }

    function logOut() {
      signOut(auth).then(() => {
        localStorage.removeItem("userId");
        window.location.reload()
      }).catch(err => console.log(err))
    }
  
    return(
      <div className="App">
          <h1>Monate:</h1>
            <div id='tasksContainer'>
            {
              Months.map(month => {
                return(
                      <a className='monthTitle' href={'#' + month.id} key={Math.random()}>{month.monthName}</a>
                )
              })
            }
            </div>
            <div id='addExp'>
            <h1 className="addTitle">Monat hinzufügen:</h1>
                  <div>
                      <input type="text" ref={monthTbRef} id="addTitleTb" placeholder='Monat' />
                      <button id='addBtn' onClick={() => addMonth()}>Hinzufügen</button>
                  </div>
            </div>


            <button id='logOutBtn' onClick={() => logOut()}>Ausloggen</button>
      </div>
    )
  
  
}

export default MainDashboard